<template>
  <div class="app-container">
    <div style="margin:5px 0px;">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="姓名:">
          <el-input placeholder="请输入姓名"  v-model="search.expertName" size='medium' class="form-line-item ">
          </el-input>
        </el-form-item>
        <el-form-item  label="单位:">
          <el-input placeholder="请输入单位名称"   v-model="search.expertInstitution" size='medium' class="form-line-item">
          </el-input>
        </el-form-item>


        <el-form-item label="评审意愿:">
          <el-select v-model="search.accept" class="smallSearchBox" size="medium" clearable placeholder="评审意愿等级" multiple>
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="评审质量:">
          <el-select v-model="search.quality" class="smallSearchBox" size="medium" clearable placeholder="评审质量等级" multiple>
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


      <el-form-item label="评审速度:">
        <el-select v-model="search.frequency" class="smallSearchBox" size="medium" clearable placeholder="评审速度等级" multiple>
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="活跃度:">
        <el-select v-model="search.active" class="smallSearchBox"  size="medium" clearable placeholder="活跃度" multiple>
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item style="margin-left:10px;">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
            搜索
          </el-button>
          <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
            重置
          </el-button>
        </el-form-item>
      </el-form>

    </div>

    <!-- 表格 -->
    <div>
      <!-- <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;">
        <template #buttons>
          <el-button type="success" size="small" @click="singleInsert()">导入</el-button>
          <el-button type="success" size="small" @click="batchInsert()">批量导入</el-button>
        </template>
      </vxe-toolbar> -->
      <!-- :loading="loading" -->
      <vxe-table :header-cell-style="headerCellStyle" round align="center" ref="schoolTable" highlight-current-row
        highlight-hover-row :keyboard-config="{ isArrow: true }" keep-source class="mytable-scrollbar expert-table"
        :checkbox-config="{ trigger: 'cell', highlight: true, range: false }" :loading='loading' :data="expertList"
        :edit-config="{ trigger: 'manual', mode: 'row' }" :expand-config="{ accordion: true }"
        >
        <vxe-column type='seq' title="序号" width="5%" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="expertId" title="ID" width="20%" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="expertName" title="姓名" width="20%" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column field="institutionName" title="单位" width="15%" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column field="accept" title="评审意愿" width="10%" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column field="quality" title="评审质量" width="10%" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column field="frequency" title="评审速度" width="10%" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column field="active" title="活跃度" width="10%" :show-overflow="'tooltip'">
        </vxe-column>


      </vxe-table>
<!--      <vxe-pager-->
<!--          background-->
<!--          :current-page.sync="pageVO2.currentPage"-->
<!--          :page-size.sync="pageVO2.pageSize"-->
<!--          :total="pageVO2.total"-->
<!--          :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">-->
<!--      </vxe-pager>-->
      <!--'Home', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'End', 'Sizes', 'FullJump', 'Total'-->
        <vxe-pager  align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                   :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                   :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"

                   @page-change="handlePageChange">
            <template #left>
                <vxe-button size="small" @click="firstPage">首页
                </vxe-button>
            </template>
          <template #right>
            <div>


              <div style="margin-left: 10px;">
                  <vxe-button size="small" @click="endPage">尾页</vxe-button>
                第{{ page.currentPage }}/{{page.allPages}}页</div>
            </div>

          </template>

        </vxe-pager>




<!--      <vxe-pager :current-page.sync="pageVO2.currentPage" :page-size.sync="pageVO2.pageSize" :total="pageVO2.total" size="medium" />-->





      <vxe-modal v-model="showEdit" title="信息修改" width="60%" height="100%" show-footer show-zoom resize remember>
        <template #default>
          <el-divider content-position="left" class="divider"><span style="color:#409EFF;">专家个人信息</span>
          </el-divider>
          <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm1">
            <vxe-form-item title="姓名：" field="name" span="12">
              <template #default>
                <vxe-input v-model="infoChange.name" placeholder="请输入名称"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="单位：" field="university" span="12">
              <template #default>
                <!-- <vxe-select v-model="infoChange.university" placeholder="请选择单位">
                                <vxe-option v-for="(item,index) in universityList" :key="index"
                                    :label="item.universityName" :value="item.universityName"></vxe-option>
                            </vxe-select> -->
                <el-select v-model="infoChange.university" filterable class="form-line-item" size="small"
                  placeholder="请选择单位">
                  <el-option v-for="item in universityList" :label="item.universityName" :value="item.universityName">
                  </el-option>
                </el-select>
              </template>
            </vxe-form-item>
            <vxe-form-item title="邮箱：" field="mail" span="12">
              <template #title>
                <div>
                  <!-- <el-radio v-model="infoChange.defaultContactType" :label="0">邮箱：</el-radio> -->
                  邮箱：
                </div>
              </template>
              <template #default>
                <vxe-input v-model="infoChange.mail" placeholder="请输入邮箱"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="手机号:" field="mobile" span="12">
              <template #title>
                <div>
                  <!-- <el-radio v-model="infoChange.defaultContactType" :label="1">手机：</el-radio> -->
                  手机
                  <el-tooltip class="item" effect="dark" content="请填写您的常用手机号，用于税务系统申报个人所得税" placement="top">
                    <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                    </el-image>
                  </el-tooltip>
                </div>
              </template>
              <template #default>
                <vxe-input v-model="infoChange.mobile" placeholder="请输入手机号"></vxe-input>
              </template>
            </vxe-form-item>
          </vxe-form>
          <!-- <span style="margin-left:11%;font-size:12px;color:red;">
                        注：请填写邮箱和手机号，并勾选邮件（推荐）或手机号作为您的首选联系方式。
                    </span> -->
          <el-divider content-position="left" class="divider"><span style="color:#409EFF;">专家学术信息</span>
          </el-divider>
          <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm2">
            <vxe-form-item title="职称：" field="title" span="12">
              <template #default>
                <vxe-select v-model="infoChange.title" placeholder="请选择专家职称" class="form-line-item">
                  <vxe-option label="正高级" value="1"></vxe-option>
                  <vxe-option label="副高级" value="0"></vxe-option>
                  <vxe-option label="中级" value="2"></vxe-option>
                  <vxe-option label="其他" value="3"></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
            <vxe-form-item title="导师类型：" field="type" span="12">
              <template #default>
                <vxe-select v-model="infoChange.type" placeholder="请选择导师类型">
                  <vxe-option label="博士生导师" value="1"></vxe-option>
                  <vxe-option label="硕士生导师" value="0"></vxe-option>
                  <vxe-option label="其他" value="2"></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
            <vxe-form-item title="人才称号：" field="honors" span="12">
              <template #default>
                <!-- <vxe-select v-model="infoChange.honors" filterable placeholder="选填"
                                class="form-line-item">
                                <vxe-option label="院士" value="院士"></vxe-option>
                                <vxe-option label="千人" value="千人"></vxe-option>
                                <vxe-option label="长江" value="长江"></vxe-option>
                                <vxe-option label="杰青" value="杰青"></vxe-option>
                                <vxe-option label="四青" value="四青"></vxe-option>
                            </vxe-select> -->
                <el-select v-model="infoChange.honors" filterable multiple allow-create placeholder="选填，可在下拉框中勾选，也可直接填写"
                  class="form-line-item" size="small">
                  <el-option label="院士" value="院士"></el-option>
                  <el-option label="千人" value="千人"></el-option>
                  <el-option label="长江" value="长江"></el-option>
                  <el-option label="杰青" value="杰青"></el-option>
                  <el-option label="四青" value="四青"></el-option>
                </el-select>
              </template>
            </vxe-form-item>
            <vxe-form-item title="学科：" field="major" span="12">
              <template #default>
                <el-cascader v-model="infoChange.expertMajor" :options="majorList"
                  :props="{ expandTrigger: 'hover', value: 'label', label: 'label' }" :show-all-levels='false' filterable
                  class="form-line-item" size="small" placeholder="请选择学科"></el-cascader>
              </template>
            </vxe-form-item>
            <vxe-form-item title="研究方向：" field="expert_theme" span="24">
              <template #default>
                <vxe-input v-model="infoChange.expert_theme" placeholder="请输入研究方向"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="关键词:" field="keywords" span="24">
              <template #title>
                <span>
                  关键词
                  <el-tooltip class="item" effect="dark" content="请至少填写5个学术关键词。您还可以通过+号录入更多的关键词，以便更加精准地邀请您审稿。"
                    placement="top">
                    <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                    </el-image>
                  </el-tooltip>
                </span>
              </template>
              <template #default>
                <div>
                  <div v-for="(item, index) in keywords" class="keywords" :key='index'>
                    <el-input v-model="keywords[index]" style="width:95%" size="medium" class='inputChange1'>
                    </el-input>
                  </div>
                  <i class="el-icon-circle-plus" style="color:#409EFF;" @click="insertHandle"></i>
                </div>
              </template>
            </vxe-form-item>
          </vxe-form>
          <el-divider content-position="left" class="divider"><span style="color:#409EFF;">评审费支付信息</span>
          </el-divider>
          <vxe-form :data="infoChange" :rules="rules" title-align="right" title-width="100" ref="ruleForm3">
            <vxe-form-item title="支付方式：" field="defaultPayType" span="24">
              <template #default>
                <el-radio-group v-model="payType" size="small">
                  <!-- <el-radio :label="0" border>支付宝</el-radio>
                                <el-radio :label="1" border>微信</el-radio> -->
                  <el-radio :label="2" border>银行卡</el-radio>
                </el-radio-group>
              </template>
            </vxe-form-item>
            <vxe-form-item title="开户人：" field="bankName" span="24">
              <template #title>
                <span>
                  开户人
                  <el-tooltip class="item" effect="dark" content="需要使用您本人银行卡，“开户人”信息与“姓名”信息一致" placement="top">
                    <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                    </el-image>
                  </el-tooltip>
                </span>
              </template>
              <template #default>
                <vxe-input v-model="infoChange.name" placeholder="请输入开户人姓名" disabled></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="身份证号:" field="IDNum" span="24">
              <template #title>
                <span>
                  身份证号
                  <el-tooltip class="item" effect="dark" content="用于支付评审费及在税务系统申报个人所得税" placement="top">
                    <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                    </el-image>
                  </el-tooltip>
                </span>
              </template>
              <template #default>
                <vxe-input v-model="infoChange.IDNum" placeholder="请输入开户人身份证号"></vxe-input>
              </template>
            </vxe-form-item>
            <!-- <vxe-form-item title="支付宝号:" field="alipayId" key="alipay1" span="24"
                        v-if='infoChange.defaultPayType == 0'>
                        <template #default>
                            <vxe-input v-model="infoChange.alipayId" key="alipay" placeholder="请输入支付宝号"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item title="微信账号:" field="wechatId" key="wechat1" span="24"
                        v-if='infoChange.defaultPayType == 1'>
                        <template #default v-if='infoChange.defaultPayType == 1'>
                            <vxe-input v-model="infoChange.wechatId" key="wechat" placeholder="请输入微信号"></vxe-input>
                        </template>
                    </vxe-form-item> -->
            <vxe-form-item title="银行卡号:" field="bankCardNum" key="bankCardNum1" span="24" v-if='payType == 2'>
              <template #title>
                <span>
                  银行卡号
                  <el-tooltip class="item" effect="dark" content="用于支付评审费，请确保银行卡号准确，且不要包含空格等。" placement="top">
                    <el-image style="width: 15px; height: 15px" :src="require('@/assets/img/gt.png')">
                    </el-image>
                  </el-tooltip>
                </span>
              </template>
              <template #default>
                <vxe-input v-model="infoChange.bankCardNum" key="bankCardNum" placeholder="请输入银行卡号">
                </vxe-input>
              </template>
            </vxe-form-item>
          </vxe-form>
          <!-- <div class="tip" style="padding-left:10px;color:blue;">
                     郑重承诺：江苏高擎论文评审中心承诺对于您填写的所有信息将严格保密，并保证信息安全不泄露。
                </div> -->
        </template>
        <template #footer>
          <vxe-button @click="cancelHandle" size="medium">取 消</vxe-button>
          <vxe-button status="primary" @click="handleSaveInfo">
            保 存</vxe-button>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import toolNull from '@/tools/null'
export default {
  name: 'expertInfo',
  data() {
    return {
      payMethod: {
        0: {
          text: '支付宝',
          value: 'alipayId'
        },
        1: {
          text: '微信',
          value: 'wechatId'
        },
        2: {
          text: '银行卡',
          value: 'bankCardNum'
        }
      },
      loading: false,
      search: {
        expertName: '',
        expertMajor: '',
        customMajor: '',
        grade: '',
        type: '',
        title: '',
        honors: '',
        mail: '',
        mobile: '',
        expertInstitution: '',
        bankCardNum:"",
        active:[],
        accept:[],
        quality:[],
        frequency:[]

      },
      expertType:'',
      expertTitle:'',
      //论文列表
      expertList: [],
      //分页
      page: {
        currentPage: 1,
        pageSize: 10,
        pageCount: 40, //自己用
        pageSizes: [1, 5, 10, 15, 20],
        total: 0,
        allPages:1
      },
      //编辑对话框
      showEdit: false,
      isEdit: true,
      infoChange: {
        expertId: '',
        name: '',
        university: '',
        mail: '',
        mobile: '',
        title: '',
        type: '',
        customMajor: '',
        submajor: '',
        theme: '',
        keywords: '',
        defaultPayType: 0,
        bankName: '',
        payAccount: '',
        alipayId: '',
        wechatId: '',
        bankCardNum: '',
        IDNum: '',
        defaultContactType: 1,
      },
      submitLoading: false,
      universityList: [],
      majorList: [],
      payType: 2,
      keywords: ['', '', '', '', ''],
      expertTypeList:[{
        expertTypeId:0,
        expertTypeName:'硕导'
      },{
        expertTypeId:1,
        expertTypeName:'博导'
      },{
        expertTypeId:2,
        expertTypeName:'其他'
      },{
        expertTypeId:'',
        expertTypeName:'全部'
      }],
      expertTitleList:[{
        expertTitleId:0,
        expertTitleName:'副高级'
      },{
        expertTitleId:1,
        expertTitleName:'正高级'
      },{
        expertTitleId:2,
        expertTitleName:'中级'
      },{
        expertTitleId:3,
        expertTitleName:'其他'
      },{
        expertTitleId:'',
        expertTitleName:'全部'
      }],
      options:[{
        value: 'A',
        label: 'A'
      }, {
        value: 'B',
        label: 'B'
      }, {
        value: 'C',
        label: 'C'
      }],
      pageVO2: {
        currentPage: 1,
        pageSize: 30,
        total: 8
      }
    }
  },
  created() {
      window.addEventListener('keydown', this.handleKeyPress);
    // this.batchSearch();
    this.getMajor();
    this.getUniversityList();
  },
  methods: {
      handleKeyPress(event) {
          if (event.keyCode === 13 && !this.showEdit) {
              this.batchSearch();
          }
      },

      //获取院校列表
    getUniversityList() {
      this.$api.basicInfo.getUniversity()
        .then(res => {
          this.universityList = res.data.data;
        })
        .catch(err => {
          this.$message.warning("服务器维护");
        });
    },
    //获取一级学科
    getMajor() {
      this.$api.basicInfo.getAllSubject()
        .then(res => {
          this.majorList = res.data.data;
        })
        .catch(err => {
          this.$message.warning("服务器维护！");
        })
    },
    //获取论文列表
    getexpertList() {
      this.loading = true;
      // let param = new URLSearchParams();
      // param.append("expertName", this.search.expertName);
      // param.append("expertMajor", this.search.expertMajor);
      // param.append("type", this.expertType);
      // // param.append("type", this.search.type);
      // param.append("title", this.expertTitle);
      // // param.append("title", this.search.title);
      // param.append("honors", this.search.honors);
      // param.append("mail", this.search.mail);
      // param.append('mobile', this.search.mobile);
      // param.append('expertInstitution', this.search.expertInstitution);
      //   param.append('bankCardNum', this.search.bankCardNum);
      //
      // param.append("pageNum", this.page.currentPage);
      // param.append("pageSize", this.page.pageSize);

        let param = {
            expertName:this.search.expertName,
            expertInstitution:this.search.expertInstitution,
            accept:this.search.accept,
            quality:this.search.quality,
            frequency:this.search.frequency,
            active:this.search.active,

            pageNum:this.page.currentPage,
            pageSize:this.page.pageSize,
        }
        console.log(param)
      this.$api.basicInfo.getExpertInfo_v2(param)
        .then(res => {
            console.log(res)
          this.expertList = res.data.data;


           console.log(this.expertList)
          this.page.total = res.data.count;
          this.page.allPages = Math.ceil(this.page.total/this.page.pageSize);
          // if(this.page.total<this.page.pageSize){
          //     this.page.currentPage = 1;
          // }

          this.loading = false;
        }).catch(err => {
          this.$message.warning("服务器维护！");
          this.loading = false;
        });
    },
    //搜索
    batchSearch() {
      // if (this.search.expertName == '' && this.search.expertInstitution == '' && this.search.accept==''&& this.search.quality==''&& this.search.frequency==''&& this.search.active=='') {
      //   return;
      // }
      // console.log(this.search);
      // if (!this.search.expertName && !this.search.mail && !this.search.mobile) {
      //   this.$message.warning("至少提供姓名、邮箱、手机号其中之一");
      //   return;
      // }
      this.getexpertList();
    },
    resetHandle() {
      this.search = {
        expertName: '',
        expertInstitution: '',
        accept:[],
        quality: [],
        frequency: [],
        active: [],
      };
    },
    singleInsert() {
      this.showEdit = true;
      this.isEdit = false;
      this.infoChange = {
        name: '',
        university: '',
        mail: '',
        mobile: '',
        title: '',
        type: '',
        customMajor: '',
        submajor: '',
        theme: '',
        keywords: '',
        defaultPayType: 0,
        bankName: '',
        payAccount: '',
        alipayId: '',
        wechatId: '',
        bankCardNum: '',
        IDNum: '',
        defaultContactType: 0,
        honors: ''
      }
    },
    //提交添加的账号
    confirmInsert() {
      let loading = this.$loading({
        lock: true,
        text: '正在保存',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.isEdit) {
        let params = {
          expertId: this.infoChange.expertId,
          name: this.infoChange.name,
          university: this.infoChange.university,
          mobile: this.infoChange.mobile,
          mail: this.infoChange.mail,
          title: this.infoChange.title,
          type: this.infoChange.type,
          customMajor: this.infoChange.customMajor ? this.infoChange.customMajor.join(
            ',') : '',
          expert_theme: this.infoChange.expert_theme,
          keywords: this.infoChange.keywords,
          honors: this.infoChange.honors ? this.infoChange.honors.join(',') : '',
          defaultPayType: this.infoChange.defaultPayType,
          alipayId: this.infoChange.alipayId,
          wechatId: this.infoChange.wechatId,
          bankCardNum: this.infoChange.bankCardNum,
          idnum: this.infoChange.IDNum,
          bank: this.infoChange.bank,
          bankName: this.infoChange.name,
          url: '',
          defaultContactType: this.infoChange.defaultContactType,
          expertMajor: this.infoChange.expertMajor
        };
        this.$api.basicInfo.postUpdateExpert(params)
          .then(res => {
            loading.close();
            if (res.data.code == 200) {
              this.$message.success("修改成功！");
              this.batchSearch();
              this.showEdit = false;
            } else {
              this.$message.warning("修改失败！");
            }
          }).catch(err => {
            loading.close();
            this.$message.warning("服务器维护!");
          })
      } else {
        let param = {
          data: [
            this.infoChange
          ]
        };
        this.$axios.post('/user/import_expert', param)
          .then(res => {
            if (res.data.code > 0) {
              this.$message.success("添加成功！");
              this.batchSearch();
              this.showEdit = false;
            } else {
              this.$message.warning("添加失败！");
            }
          }).catch(err => {
            this.$message.warning("服务器维护！");
          })
      }
    },
    //重置添加表单
    resetInsert() {
      this.infoChange = {
        name: '',
        university: '',
        mail: '',
        mobile: '',
        title: '',
        type: '',
        customMajor: '',
        submajor: '',
        theme: '',
        keywords: '',
        defaultPayType: '0',
        bankName: '',
        payAccount: '',
        alipayId: '',
        wechatId: '',
        bankCardNum: '',
        honors: '',
        IDNum: '',
        defaultContactType: 0
      }
    },
    editLevel(level, expertId) {
      let params = {
        expertId: expertId,
        priority: level,
        blockingReason: ''
      };
      this.$api.basicInfo.setExpertPriority(params)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("修改成功！");
          }
        }).catch(err => {
          this.$message.warning("服务器维护!");
        })
    },
    //删除账号
    removeHandle(id) {
      let param = {
        expertId: id
      };
      this.$axios.post('/user/delete_expert', param)
        .then(res => {
          if (res.data.data == 1) {
            this.$message.success('删除成功！');
            this.batchSearch();
          } else {
            this.$message.warning('删除失败！');
          }
        }).catch(err => {
          this.$message.warning("服务器维护！");
        })
    },
    //修改账号
    editHandle(row) {
      this.expertId = row.expertId;
      let params = {
        expertId: row.expertId
      }
      this.$api.basicInfo.get_expert(params)
        .then(res => {
          if (res.data.code == 200) {
            this.infoChange = {
              name: toolNull.null2Empty(res.data.data.name),
              university: toolNull.null2Empty(res.data.data.university),
              mobile: toolNull.null2Empty(res.data.data.mobile),
              mail: toolNull.null2Empty(res.data.data.mail),
              title: toolNull.null2Empty(res.data.data.title),
              type: toolNull.null2Empty(res.data.data.type),
              expert_theme: toolNull.null2Empty(res.data.data.expert_theme),
              major: res.data.data.customMajor == null ? '' : res.data.data.customMajor.split(
                ','),
              honors: res.data.data.honors == null || res.data.data.honors == '' ? [] : res
                .data.data.honors
                .split(','),
              keywords: toolNull.null2Empty(res.data.data.customKeywords),
              bankCardNum: toolNull.null2Empty(res.data.data.bankCardNum),
              bank: toolNull.null2Empty(res.data.data.bank),
              bankName: toolNull.null2Empty(res.data.data.bankName),
              IDNum: toolNull.null2Empty(res.data.data.idnum),
              url: toolNull.null2Empty(res.data.data.url),
              alipayId: toolNull.null2Empty(res.data.data.alipayId),
              wechatId: toolNull.null2Empty(res.data.data.wechatId),
              defaultPayType: toolNull.null2Empty(res.data.data.defaultPayType),
              defaultContactType: toolNull.null2Empty(res.data.data.defaultContactType),
              expertMajor: res.data.data.expertMajor == null ? '' : res.data.data.expertMajor.split(',')
            };
            this.showEdit = true;
            this.isEdit = true;

            if (this.infoChange.keywords != '') {
              let arr = this.infoChange.keywords.split(',');
              for (let i = arr.length; i < 5; i++) arr.push('');
              this.keywords = arr;
            } else {
              this.keywords = ['', '', '', '', ''];
            }
          }
        }).catch(err => {
          this.$message.warning("获取专家信息失败");
        });
    },

    async handleSaveInfo() {
      // let flag = true;
      // await this.$refs['ruleForm1'].validate((callback) => {
      //   console.log(callback);
      //   if (callback) {
      //     flag = false;
      //     return false;
      //   }
      // });
      // await this.$refs['ruleForm2'].validate((callback) => {
      //   console.log(callback);
      //   if (callback) {
      //     flag = false;
      //     return false;
      //   }
      // });
      // await this.$refs['ruleForm3'].validate((callback) => {
      //   console.log(callback);
      //   if (callback) {
      //     flag = false;
      //     return false;
      //   }
      // });
      // if (!flag) return;
      let loading = this.$loading({
        lock: true,
        text: '正在保存',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let arr = [];
      this.keywords.forEach(item => {
        if (item != '') arr.push(item);
      });
      let param = {
        expertId: this.expertId,
        name: this.infoChange.name,
        university: this.infoChange.university,
        mobile: this.infoChange.mobile,
        mail: this.infoChange.mail,
        title: this.infoChange.title,
        type: this.infoChange.type,
        customMajor: Array.isArray(this.infoChange.major) ? this.infoChange.major.join(',') : '',
        expert_theme: this.infoChange.expert_theme,
        keywords: arr.join(','),
        honors: this.infoChange.honors.join(','),
        defaultPayType: this.infoChange.defaultPayType,
        alipayId: this.infoChange.alipayId,
        wechatId: this.infoChange.wechatId,
        bankCardNum: this.infoChange.bankCardNum,
        idNum: this.infoChange.IDNum,
        bank: this.infoChange.bank,
        bankName: this.infoChange.name,
        url: '',
        defaultContactType: this.infoChange.defaultContactType,
        expertMajor: Array.isArray(this.infoChange.expertMajor) ? this.infoChange.expertMajor.join(',') : ''
      };
      // console.log(param);
      this.$api.basicInfo.update_expert(param)
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.showEdit = false;
            // this.$store.dispatch('changeIsChangeDiaVis', false);
            this.getexpertList(this.expertId);
            loading.close();
            this.$message.success('更新成功！');
          } else {
            this.$message.warning('更新失败！');
            loading.close();
          }
        })
        .catch(err => {
          this.$message.warning("服务器维护！");
          loading.close();
        })
    },

    //添加关键字
    insertHandle() {
      for (let i = 0; i < 5; i++) this.keywords.push('');
    },
    //保存
    saveHandle(row) {
      const $table = this.$refs.schoolTable
      $table.clearActived().then(() => {
        // this.loading = true
        console.log(row);
      })

    },
    //取消
    cancelHandle(row) {
      const $table = this.$refs.schoolTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    },
    //进入账号
    enterHandle() { },
    //分页操作
    handlePageChange({
      currentPage,
      pageSize
    }) {
      console.log(currentPage, pageSize);
      this.page.currentPage = currentPage;
      this.page.pageSize = pageSize;
      // this.allPages =
      this.getexpertList(this.search, this.page.currentPage, this.page.pageSize);
    },
    //每页多少数据改变
    handleChangePageSize(val) {
      this.page.pageSize = val;
      this.page.currentPage = 1;
      this.getexpertList(this.search, this.page.currentPage, this.page.pageSize);
    },
    firstPage() {
      this.page.currentPage = 1;
      this.getexpertList(this.search, this.page.currentPage, this.page.pageSize);
    },
    endPage() {
      this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
        .pageSize == 0 ? 0 : 1);
      this.getexpertList(this.search, this.page.currentPage, this.page.pageSize);
    },
    headerCellStyle({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return "background-color:#e0e0e0f1"
    },
  },
  components: {

  }
}
</script>

<style scoped>
.keywords {
  width: 20%;
  float: left;
  margin-bottom: 5px;
}
.smallSearchBox{
  width: 180px;
}
.form-line-item {
  width: 100%;
}
</style>

<style>
  .expert-table .vxe-table--body-wrapper {
    height: calc(100vh - 320px);
  }
</style>
